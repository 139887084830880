;(function(){
	/* Quiz client code
	 * Author: cja@infotjenester.no
	 */

	 const api =  'https://quiz.simployer.com/api/quiz/';

	 let OnComplete = null;

	 function Quiz(id,element){
	 	let Public = {};
	 	let Element = element;
	 	let Data = null;
	 	let QuestionIndex = -1;
	 	let Costs = null;
	 	let HasCosts = false;
	 	let Faults = 0;
		let ImmediateSolution = false;
		let FasitTexts = [];
	 	let Stats = {};


	 	function getQuiz(id,callback){
 			var req = new XMLHttpRequest();
 			req.open('GET',api + id);

 			req.onload = function(){
 				if(req.readyState === 4){
 					if(req.status < 400){
 						callback(null,JSON.parse(req.responseText))
 					}else{
 						callback(new Error(req.responseText),null);
 					}
 				}
 			}

 			req.send();
	 	}

	 	function sendStats(){
	 		var data = {
	 			stats: Stats
	 		};

	 		var req = new XMLHttpRequest();
	 		req.open('POST',api + id + '/stats');
	 		req.setRequestHeader('Content-Type','application/json');
	 		req.send(JSON.stringify(data));
	 	}

	 	function render(template){
	 		Element.innerHTML = template;
	 	}

	 	function bindEvents(){
	 		Element.addEventListener('click',function(e){

	 			let el = e.target;

	 			while(el && el !== Element && (el.getAttribute('event') === null)) {
	 				el = el.parentElement;
	 			}

	 			let event = el.getAttribute('event');

	 			if(event === 'next'){
	 				next();
	 			}
	 			if(event === 'answer'){
	 				let answerId = e.target.getAttribute('answer-id');
	 				checkAnswer(answerId);
	 			}
	 			if(event === 'restart'){
	 				QuestionIndex = -1;
	 				Costs = 0;
	 				Faults = 0;
	 				//next();
					render(renderHeader(Data))
	 			}
	 		},false)
	 	}

	 	function checkAnswer(id){
	 		if(!id){return;}
	 		var answer = Data.questions[QuestionIndex].answers.reduce((val,item) => {
	 			if(item._id === id){
	 				val = item;
	 			}
	 			return val;
	 		},{});

	 		if(!answer.state){
	 			Faults++;
	 			Costs += answer.cost;
				FasitTexts.push(answer.explanation);
	 		}

	 		Stats[Data.questions[QuestionIndex]._id] = {
	 			answerId: id,
	 			state: answer.state
	 		};

	 		if(Data.questions.length < 2 && (!answer.explanation || !ImmediateSolution)){
	 			next();
	 		}else{
	 			if(!answer.explanation || !ImmediateSolution) next();
	 			else render(renderAnswerResult(answer));
	 		}
	 	}

	 	function next(){
	 		QuestionIndex++;
	 		if(QuestionIndex < Data.questions.length){
	 			render(renderQuestion(Data.questions[QuestionIndex]));
	 		}else{
	 			render(renderResult());
				if(OnComplete && typeof OnComplete === 'function') OnComplete({
					quiz: Data,
					stats: {
						questions: Data.questions.length,
						faults: Faults,
						correct: Data.questions.length - Faults,
						score: Math.round((Data.questions.length - Faults) / Data.questions.length * 100) / 100
					}
				});
	 			sendStats();
	 		}
	 	}

	 	function init(){
	 		getQuiz(id,function(err,data){
	 			if(err){
	 				switch(navigator.language){
	 					case 'nb':
	 					case 'nn':
	 						return render(`<h1 style="color: #FF7860;margin-bottom: 1em;">Ops!</h1>Det oppstod problemer med denne quizen!<hr><p style="font-style:italic;">Prøv igjen senere!</p>`);
	 					case 'sv':
	 						return render(`<h1 style="color: #FF7860;margin-bottom: 1em;">Hoppsan!</h1>Ett fel har uppstått med det här quizzet!<hr><p style="font-style:italic;">Försök igen senare!</p>`);
	 					default:
	 						return render(`<h1 style="color: #FF7860;margin-bottom: 1em;">Oops!</h1>There were problems with this quiz!<hr><p style="font-style:italic;">Try again later</p>`);
	 				}
	 			}

	 			Data = data;

				ImmediateSolution = Data.resultTexts.reduce(function (val, item) {
					if(~item.text.indexOf('{fasit}')) val = false;
					return val;
				}, true);

	 			HasCosts = Data.questions.reduce(function(val,item){
	 				let x = item.answers.reduce(function(y,item){
	 					if(item.cost){
	 						y = true;
	 					}
	 					return y
	 				},false)

	 				if(x){
	 					val = true;
	 				}
	 				return val;
	 			},false);
	 			render(renderHeader(Data));
	 			bindEvents();
	 		});

	 		return Public;
	 	}

	 	function renderHeader(data){
	 		let description = (strip(data.description))?`<hr><div class="description animated fadeIn">${data.description}</div>`:'';
	 		return `<h1 class="animated fadeIn">${data.title}</h1>
	 		${description}
	 		<button type="button" class="start animated flipInX" event="next">${playIcon}</button>`;
	 	}

	 	function renderAnswerResult(data){
	 		let icon = (data.state)?successIcon:errorIcon;
	 		let color = (data.state)?'#63C572':'#FF7869';
	 		return `${icon}
	 		${data.explanation}
	 		<button type="button" event="next" class="animated fadeIn" style="background: ${color}">${playIcon}</button>`;
	 	}

	 	function renderResult(){

	 		let resultText = Data.resultTexts.reduce(function(val,item){
	 			if(item.minFaults <= Faults && !val){
	 				val = item;
	 			}
	 			return val;
	 		},null) || {text: ''};


	 		resultText = TemplateEngine(resultText.text,{
	 			feil: Faults,
	 			kostnad: Costs,
				fasit: `<div class="fasit-container">${FasitTexts.join('')}</div>`
	 		});
	 		let footer = (Faults)?`<button type="button" style="background: #FF7869;padding: 15px 60px;" event="restart">${refreshIcon}</button>`:``;
	 		return `<div class="quiz-result">
	 		${resultText}
	 		${footer}
	 		</div>`;
	 	}

	 	function renderQuestion(data){
	 		let answers = data.answers.map(answer => renderAnswer(answer)).join('');
	 		let costClass = (Costs)?'negative':'positive';
	 		let costs = (HasCosts)?`<div class="costs ${costClass}"><img src="/img/coins.png" alt="">Kostnad: <b style="margin-left:.5em;">${format(Costs)}</b></div>`:'';
	 		return `<div class="question-box animated fadeInDown">
	 		${data.question}
	 		</div>
	 		<hr>
	 		<ol class="answers animated fadeIn">
	 		${answers}
	 		</ol>

	 		<div class="submit-container">
	 		${costs}
	 		<div class="quiz-progress">
	 		<p>${QuestionIndex + 1} / ${Data.questions.length}</p>
	 		<div class="quiz-progress-container">
	 		<div class="quiz-progress-bar" style="width: ${Math.round((QuestionIndex + 1) / Data.questions.length * 100)}%"></div>
	 		</div>
	 		</div>
	 		</div>`;
	 	}

	 	function renderAnswer(data){
	 		return `<li>
	 		<label answer-id="${data._id}" event="answer">${data.text}</label>
	 		</li>`;
	 	}

	 	let successIcon = '<svg class="animated rotateIn" style="width: 75px;height:75px;display:block;margin:1em auto;" viewBox="0 0 32 32"><path d="M16,0C7.164,0,0,7.164,0,16s7.164,16,16,16s16-7.164,16-16S24.836,0,16,0z M13.52,23.383   L6.158,16.02l2.828-2.828l4.533,4.535l9.617-9.617l2.828,2.828L13.52,23.383z" fill="#63C572"/></svg>';
	 	let errorIcon = '<svg class="animated rotateIn" style="width: 75px;height:75px;display:block;margin:1em auto;" viewBox="0 0 32 32"><path d="M16,0C7.164,0,0,7.164,0,16s7.164,16,16,16s16-7.164,16-16S24.836,0,16,0z M23.914,21.086   l-2.828,2.828L16,18.828l-5.086,5.086l-2.828-2.828L13.172,16l-5.086-5.086l2.828-2.828L16,13.172l5.086-5.086l2.828,2.828   L18.828,16L23.914,21.086z" fill="#FF7869" /></svg>';
	 	let refreshIcon = '<svg style="width: 25px;height:25px;position: relative;top: 3px;" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"><path d="M25.032,26.16c2.884-2.883,4.184-6.74,3.928-10.51c-1.511,0.013-3.021,0.021-4.531,0.034  c0.254,2.599-0.603,5.287-2.594,7.277c-3.535,3.533-9.263,3.533-12.796,0c-3.534-3.533-3.534-9.26,0-12.794  c3.015-3.016,7.625-3.446,11.109-1.314c-1.181,1.167-2.57,2.549-2.57,2.549c-1,1.062,0.016,1.766,0.69,1.77h8.828  c0.338,0,0.611-0.274,0.612-0.612V3.804c0.041-0.825-0.865-1.591-1.756-0.7c0,0-1.495,1.48-2.533,2.509  C18.112,1.736,10.634,2.175,5.841,6.967c-5.3,5.3-5.3,13.892,0,19.193C11.141,31.459,19.733,31.459,25.032,26.16z" fill="#ffffff"/></svg>';
	 	let playIcon = '<svg width="35" height="35" fill="#fff" style="position: relative;top: 3px;" viewBox="0 0 512 512"><path d="m512 252c-1-1-1-1-1-2c0-1 0-1-1-2c0-1 0-2-1-2c0-1 0-1-1-2c0-1-1-2-2-3l-107-107c-8-8-22-8-30 0c-8 9-8 22 0 30l70 71l-418 0c-11 0-21 9-21 21c0 12 10 21 21 21l418 0l-70 71c-8 8-8 21 0 30c4 4 10 6 15 6c5 0 11-2 15-6l107-107c1-1 2-2 2-3c1-1 1-1 1-2c1 0 1-1 1-2c1-1 1-1 1-2c0-1 0-1 1-2c0-3 0-5 0-8z"></path></svg>'

	 	Public = {
	 		init
	 	};

	 	return Object.freeze(Object.defineProperties(Public,{
	 		'element': {
	 			get: function(){
	 				return Element;
	 			}
	 		},
	 		'data': {
	 			get: function(){
	 				return Data;
	 			}
	 		}
	 	}));
	 }

	 function format(value){
	 	if(!value){
	 		return 'Ingen';
	 	}
	 	return 'kr ' + value.toString().split('').reverse().join('').match(/.{1,3}/g).join(' ').split('').reverse().join('');
	 }

	 function TemplateEngine(tpl, data) {
	 	var re = /{([^}]+)?}/g, match;
	 	while(match = re.exec(tpl)) {
	 		tpl = tpl.replace(match[0], data[match[1]])
	 	}
	 	return tpl;
	 }

	 function strip(html)
	 {
	 	var tmp = document.createElement("DIV");
	 	tmp.innerHTML = html;
	 	tmp = tmp.textContent || tmp.innerText || "";
	 	return tmp.trim();
	 }

	/*Initialization*/
	[].slice.call(document.querySelectorAll('itasquiz')).forEach(function(item){
		Quiz(item.getAttribute('id'),item).init();
	});

	if(!window.ItasQuiz) window.ItasQuiz = Object.freeze(Object.defineProperties({},{
		'quiz': {
			get: function(){ return Quiz }
		},
		'onComplete': {
			get: function(){
				return OnComplete;
			},
			set: function(value) {
				OnComplete = value;
			}
		}
	}));
})();
